import React from "react"
import Img from "gatsby-image"
import {  Col, Container } from "react-bootstrap"
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Navbar from "../components/navBar"
const IndexPage = ({data}) => (
  
  <>
  <Navbar>
     
  </Navbar>
    <SEO title="AND" description="ANDDesignstudio website description"/>
    <Container fluid className="text-center m-0">
    <BackgroundImage Tag="div" className="row main d-flex align-items-center" fluid={data.bg.childImageSharp.fluid} backgroundColor={`#ff4040`}>
     {/*  <Row className=" main d-flex align-items-center" >
 */}
         <Col>
        <Img fixed={data.logo.childImageSharp.fixed} />
        <h2>Exploring Posibilities</h2>

        </Col>
    {/*   </Row> */}
     </BackgroundImage>
    </Container>
    </>
)

export default IndexPage

export const query = graphql`
query {
  logo: file(relativePath: {eq:"ANDlogo.png"}) {
    childImageSharp {
      fixed(width:98,height:126){
        ...GatsbyImageSharpFixed
      }
    }
  }
  bg: file(relativePath: {eq:"img2.jpg"}) {
    childImageSharp{
      fluid(quality:90,maxWidth:2560){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`

